import Vue from 'vue';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import VueScrollTo from 'vue-scrollto';

import Rails from '@rails/ujs';
import Turbolinks from 'turbolinks';
import '../admin/public/bootstrap';
import HasState from '@common/components/HasState.vue';
import Breakpoints from '@common/components/Breakpoints.vue';
import LoadScript from '@common/components/LoadScript.vue';
import Navigation from '../application/javascripts/navigation';
import smoothScrollTo from '../application/javascripts/smooth-scroll';
import CookiesNotice from '../application/javascripts/CookiesNotice.vue';
import plausible from '../application/javascripts/plausible';

require('../styles/application.scss');

// Add all images from the "images" folder to the pipeline
require.context('../application/images', true);

Rails.start();
Turbolinks.start();

Vue.use(VueAwesomeSwiper);
Vue.use(VueScrollTo);

Vue.component('has-state', HasState);
Vue.component('breakpoints', Breakpoints);
Vue.component('load-script', LoadScript);
Vue.component('navigation', Navigation);
Vue.component('photo-gallery', () => import('../application/javascripts/PhotoGallery.vue'));
Vue.component('cookies-notice', CookiesNotice);
Vue.component('email-subscription', () =>
  import('../application/javascripts/EmailSubscription.vue')
);
Vue.component('dna-chart', () =>
  import(/* webpackChunkName: "country-compare" */ '../application/javascripts/DnaChart.vue')
);
Vue.component('atlas-map', () => import('../application/javascripts/atlas-map/AtlasMap.vue'));
Vue.component('export-pdf', () => import('../application/javascripts/ExportPDF.vue'));
Vue.component('livecasts-stream', () => import('../application/javascripts/LivecastsStream.vue'));
Vue.component('past-streams', () =>
  import('../application/javascripts/cyber-forum/PastStreams.vue')
);
Vue.component('speakers', () => import('../application/javascripts/cyber-forum/Speakers.vue'));
Vue.component('program', () =>
  import('../application/javascripts/cyber-forum/program/Program.vue')
);
Vue.component('reveal', () => import('../application/javascripts/cyber-forum/Reveal.vue'));
Vue.component('collapsible', () => import('../application/javascripts/Collapsible.vue'));

let vueApp;

// Bootstrap the Vue root instance
document.addEventListener('turbolinks:load', () => {
  vueApp = new Vue({ el: '#js-wrapper' });
  smoothScrollTo();

  setTimeout(() => {
    plausible();
  }, 2000);
});

document.addEventListener('turbolinks:visit', () => {
  if (vueApp) vueApp.$destroy();
});
