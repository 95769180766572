export default function smoothScrollTo() {
  // Select all the links that have `href` that starts with '#'
  let links = document.querySelectorAll('a[href^="#"]');

  links.forEach((link) => {
    const href = link.getAttribute('href');

    if (href === '#') return;

    const target = document.querySelector(href);

    // If the target does not exist, skip it
    if (!target) return;

    link.addEventListener('click', (e) => {
      e.preventDefault();

      // Calculate the position to scroll to, minus 100px from the top
      const targetPosition =
        target.getBoundingClientRect().top + document.documentElement.scrollTop - 100;

      // Smooth scroll to the adjusted position
      window.scrollTo({
        top: targetPosition,
        behavior: 'smooth'
      });
    });
  });
}
